import React, { useState, useEffect } from 'react'
import { useTrans } from 'hooks'
import { gql, useQuery } from '@apollo/client'
import { PRODOTTI_CORRELATI_CATEGORIA } from 'utils/queries'
import { useMenuTagsQuery } from 'gql/graphql'
import { TagCloud, Page, ProdottiCorrelati } from 'components/organisms'
import { ImageBox, Button } from 'components/atoms'

interface Props {
  className?: string
}

const Page404 = (props: Props) => {
  const { className = '' } = props
  const [prodottiSuggeriti, setprodottiSuggeriti] = useState([])
  const { data: dataConsigliati, loading: loadingConsigliati } = useQuery(
    gql`
      ${PRODOTTI_CORRELATI_CATEGORIA.replace('__chiave__', 'piu-venduti')}
    `
  )

  const t = useTrans()

  useEffect(() => {
    if (!loadingConsigliati && dataConsigliati)
      setprodottiSuggeriti(dataConsigliati.correlatiCategoria)
  }, [loadingConsigliati])

  const { data: tags } = useMenuTagsQuery()

  return (
    <Page
      pagina={{}}
      hideBreadcrumbs
      topBg="center"
      customContent={
        <>
          <ImageBox
            src="/images/returnConfirmed.png"
            maxWidth={160}
            maxHeight={150}
            classPrefix="page404"
          />
          <h1 className="page404__title">{t('Pagina non trovata')}</h1>
          <p className="page404__description">
            La pagina che hai cercato sembra non esistere ma niente paura, ti aiuteremo a trovare
            quello che stai cercando.
          </p>
        </>
      }
      className="page404"
    >
      <TagCloud
        tags={tags?.menuitem?.children?.edges}
        title={t('Prova tra i vini più gettonati')}
        className="page404__tag-cloud"
      />
      {prodottiSuggeriti.length > 0 && (
        <ProdottiCorrelati prodotti={prodottiSuggeriti} className="page404__prodotti-suggeriti" />
      )}
      <div className="page404__cta">
        <Button variant="ghost" label={t('Torna alla home')} href="/" />
        <Button
          variant="secondary"
          label={t('Tutti i prodotti più venduti')}
          href="/i-vini/selezioni/piu-venduti"
        />
      </div>
    </Page>
  )
}

export default Page404
