import Page404 from 'components/templates/Page404/Page404'
import { NextWrapper } from 'data-fetching-wrapper'

export default function Custom404() {
  return <Page404 />
}

export const getStaticProps = NextWrapper.getStaticProps(async () => {
  return {
    props: {},
    revalidate: 60 * 20,
  }
}, '/')
